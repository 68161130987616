import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { Link } from "gatsby"

import { withFirebase } from "../Firebase"
import * as ROUTES from "../../constants/routes"
import { CircularProgress } from "@material-ui/core"

const PasswordForgetForm = ({ firebase }) => {
  const { register, handleSubmit, watch, formState } = useForm()

  const isInvalid = false

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(null)
  const [error, setError] = useState(null)

  const onSubmit = (data) => {
    setLoading(true)
    setSuccess(null)
    firebase
      .doPasswordReset(data.email)
      .then(() => {
        setLoading(false)
        setSuccess({
          message:
            "Te hemos enviado un correo electrónico con las instrucciones para cambiar tu contraseña.",
        })
        setError(null)
      })
      .catch((error) => {
        console.info(error)
        setLoading(false)
        if (error.code === "auth/user-not-found") {
          setError({
            ...error,
            message: "No hay ningún usuario con este email.",
          })
        } else {
          setError(error)
        }
      })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {success && (
        <article className="box success">
          <div className="box-body">
            <p>{success.message}</p>
          </div>
        </article>
      )}
      <div className="field">
        <label>Contraseña</label>
        <input
          {...register("email", { required: true })}
          type="email"
          placeholder="Dirección de email"
        />

        {formState.errors.email && (
          <span>Dirección de email no puede estar vacío.</span>
        )}
        {error && <p>{error.message}</p>}
      </div>

      {loading ? (
        <CircularProgress />
      ) : (
        <button disabled={isInvalid} type="submit" className="fit">
          Recupera tu contraseña
        </button>
      )}
    </form>
  )
}

const PasswordForgetLink = () => (
  <Link to={ROUTES.FORGOT_PASSWORD}>He olvidado mi contraseña</Link>
)

export default withFirebase(PasswordForgetForm)

export { PasswordForgetLink }
