import React from "react"
import { useForm } from "react-hook-form"
import { Link, navigate } from "@reach/router"
import * as ROUTES from "../../constants/routes"
import { withFirebase } from "../Firebase"

const RegisterFormBase = ({ firebase }) => {
  const { register, setError, clearErrors, handleSubmit, formState } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  })

  const onSubmit = async (data) => {
    const { username, email, password } = data

    await firebase
      .doCreateUserWithEmailAndPassword(email, password)
      .then((authUser) => {
        const app = require("firebase/app")

        // Creamos user en Firebase realtime database
        return firebase.user(authUser.user.uid).set({
          username,
          email,
          createdAt: app.default.firestore.FieldValue.serverTimestamp(),
          lastLoggedInAt: app.default.firestore.FieldValue.serverTimestamp(),
        })
      })
      .then(() => {
        // Enviamos email de verificación
        firebase.doSendEmailVerification()
        navigate(`/app/`)
      })
      .catch((error) => {
        // Manejo de errores
        console.info(error)
        if (error.code === "auth/invalid-email") {
          setError("email", "invalid", "El correo electrónico no es válido.")
        }
        if (error.code === "auth/email-already-in-use") {
          setError("email", "invalid", "El correo electrónico ya está en uso.")
        }
        if (error.code === "auth/weak-password") {
          setError(
            "password",
            "invalid",
            "La contraseña debe tener al menos 6 carácteres."
          )
        }
      })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="field">
        <label>Nombre y apellidos</label>
        <input
          disabled={formState.isSubmitting}
          type="text"
          {...register("username", { required: true })}
          placeholder="Nombre y apellidos"
        />
      </div>
      <div className="field">
        <label>Email</label>
        <input
          disabled={formState.isSubmitting}
          type="text"
          {...register("email", { required: true })}
          placeholder="Correo electrónico"
          onChange={(e) => {
            clearErrors("email")
          }}
        />
        {formState.errors["email"] && (
          <div className="error">
            <span>{formState.errors["email"]["message"]}</span>
          </div>
        )}
      </div>
      <div className="field">
        <label>Contraseña</label>
        <input
          disabled={formState.isSubmitting}
          type="password"
          {...register("password", { required: true })}
          placeholder="Contraseña"
          onChange={(e) => {
            clearErrors("email")
          }}
        />
      </div>

      <div className="field">
        <input
          type="checkbox"
          {...register("accept-tos", { required: true })}
          id="accept-tos"
        />
        <label htmlFor="accept-tos">
          Acepto la política de privacidad y las condiciones de uso.
        </label>
      </div>

      <button
        disabled={!formState.isValid || formState.isSubmitting}
        type="submit"
        className={`fit ${formState.isSubmitting ? "loading" : ""}`}
      >
        Crear tu cuenta
      </button>
    </form>
  )
}

const RegisterLink = () => (
  <p>
    ¿No tienes una cuenta? <Link to={ROUTES.REGISTER}>Regístrate</Link>.
  </p>
)

export default withFirebase(RegisterFormBase)

export { RegisterLink }
