import React from "react"
import { useForm } from "react-hook-form"
import { useFirebase } from "../Firebase"
import { Link } from "@reach/router"
import * as ROUTES from "../../constants/routes"
import { PasswordForgetLink } from "../PasswordForget"

export default function LoginForm() {
  const firebase = useFirebase()
  const { register, setError, clearErrors, handleSubmit, formState } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  })

  const onSubmit = async (data) => {
    const { email, password } = data

    await firebase
      .doSignInWithEmailAndPassword(email, password)
      .then((authUser) => {
        // Actualizamos last login
        return firebase.user(authUser.user.uid).update({
          lastLoggedInAt:
            require("firebase/app").default.firestore.FieldValue.serverTimestamp(),
        })
      })
      .catch((error) => {
        console.info(error)
        if (error.code === "auth/wrong-password") {
          setError("email", {
            type: "notMatch",
            message: "Usuario o contraseña incorrecta.",
          })
        } else if (error.code === "auth/too-many-requests") {
          setError("email", {
            type: "notMatch",
            message: "Usuario o contraseña incorrecta.",
          })
        } else
          setError("email", {
            type: "notMatch",
            message: "Usuario o contraseña incorrecta.",
          })
      })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="field">
        <label>Email</label>
        <input
          disabled={formState.isSubmitting}
          type="text"
          placeholder="Correo electrónico"
          onChange={(e) => {
            clearErrors("email")
          }}
          {...register("email", { required: true })}
        />
        {formState.errors["email"] && (
          <div className="error">
            <span>{formState.errors["email"]["message"]}</span>
          </div>
        )}
      </div>

      <div className="field">
        <label>Contraseña</label>
        <input
          disabled={formState.isSubmitting}
          type="password"
          placeholder="Contraseña"
          onChange={(e) => {
            clearErrors("email")
          }}
          {...register("password", { required: true })}
        />
      </div>

      <p className="align-right">
        <PasswordForgetLink />
      </p>

      <button
        disabled={!formState.isValid || formState.isSubmitting}
        type="submit"
        className={`fit ${formState.isSubmitting ? "loading" : ""}`}
      >
        Iniciar sesión
      </button>
    </form>
  )
}

export function LoginLink() {
  return (
    <p>
      ¿Ya tienes una cuenta? <Link to={ROUTES.LOGIN}>Inicia sesión aquí</Link>.
    </p>
  )
}
