export const HOME = "/"
export const LOGIN = "/app/login"
export const REGISTER = "/app/register"
export const FORGOT_PASSWORD = "/app/pw-forgot"
export const LOGOUT = "/app/logout"
export const DASHBOARD = "/app/"
export const ADMIN = "/app/admin"
export const SETTINGS = "/app/settings"
export const SETTINGS_DETAILS = "/app/settings/billing"
export const ACCOUNT = "/app/settings/account"
export const PAYMENTS = "/app/payments"
export const PAYMENTS_NEW = "/app/payments/new"
export const MAPS = "/app/maps"
export const MAPS_NEW = "/app/maps/new"
export const REEDEM = "/app/redeem"
